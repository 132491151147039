/*#region General*/
body > iframe {
    display: none !important;
}

body {
    min-height: 100%;
    color: #555;
    background-color: #fff;
    font-family: "Open Sans",sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857;
    margin: 0;
}

.bg-light {
    background-color: #f8f9fa !important;
}

@media (min-width: 1024px) {
    .desktop-hide {
        display: none !important;
    }

    .content-container-outer {
        padding: 15px;
    }

    .content-container {
        max-width: 1600px;
        margin: 0px auto 22px;
        box-shadow: 0 0 22px 0 rgb(0 0 0 / 26%);
        -webkit-box-shadow: 0 0 22px 0 rgb(0 0 0 / 26%);
    }
}

@media (max-width: 1023px) {
    .mobile-hide {
        display: none !important;
    }

    .content-container-outer {
        padding: 10px;
    }

    .content-container {
        width: 100%;
        box-shadow: 0 0 15px 0 rgb(0 0 0 / 26%);
        -webkit-box-shadow: 0 0 15px 0 rgb(0 0 0 / 26%);
    }
}
/*#endregion*/

/*#region Top Nav Hide/Show*/
.top-nav {
    position: sticky;
    height: 200px;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 500ms;
}
.top-nav.nav-show {
    top: 0px;
}
.top-nav.nav-hide {
    top: -200px;
}

@media screen and (max-width:1023px){
    .top-nav {
        height: 110px;
    }

    .top-nav.nav-hide {
        top: -110px;
    }
}
@media screen and (max-width:400px) {
    .top-nav {
        height: 70px;
    }

    .top-nav.nav-hide {
        top: -70px;
    }
}
/*#endregion*/

/*#region Top Nav*/
.navbar{
    padding:0px !important;
}
.top-nav-top {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    flex-direction: row;
    width:100%;
}
.navbar-brand {
    background-image: url('./assets/images/NB-logo-fullcolor.svg');
    background-size: contain;
    background-repeat: no-repeat;
    padding: 0px;
    width: 225px;
    height: 80px;
    color: #0B3F54;
}

.site-title {
    font-weight: 700;
    font-size: 1.5rem;
    text-transform: uppercase;
    color: #555;
    margin-top: -18px;
}

.mr-auto.navbar-nav {
    font-weight: 700;
    font-size: 1.5rem;
    text-transform: uppercase;
}

.top-nav .dropdown-menu {
    z-index: 999999999999 !important;
}

.supplier-name .dropdown-menu {
    z-index: 999999999999 !important;
    left: -52px;
    right: 10px;
}

.supplier-name, .supplier-name .dropdown-menu a:link, .supplier-name .dropdown-menu a:visited, .supplier-name a:active, .supplier-name a:link, .supplier-name a:visited, supplier-name a:hover {
    color: #0b3f54;
}

.supplier-name .nav-link {
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: 500;
}

.navbar-toggler {
    background-color: #0b3f54 !important;
    transition: ease-in-out .25s background;
}

.collapsed {
    background-color: transparent !important;
}

@media screen and (max-width: 400px) {
    .top-nav {
        padding: 10px 10px 0px 10px !important;
    }

    .navbar-brand {
        height: auto;
        width: 180px;
    }
}
/*#endregion*/

/*#region Bottom Nav*/
.bottom-nav {
    background-color: #0b3f54;
    width: 100%;
    z-index: -1;
    left: 0;
}

.bottom-nav .navbar-nav .nav-link, .sign-off {
    color: #fff !important;
    font-weight: 500 !important;
}

.bottom-nav .navbar-nav {
    margin-left: 45px;
}

.bottom-nav .navbar-nav .nav-link {
    margin-right: 45px;
    font-size: 1.2rem;
}

.bottom-nav .dropdown-item {
    font-size: 1.1rem;
}

.bottom-nav .btn-outline-info {
    color: #fff;
    border-color: transparent;
    font-size: 1.2rem;
}

.bottom-nav .btn-outline-info:hover {
    background-color: unset !important;
    border-color: transparent !important;
}

.bottom-nav [type="button"]:focus {
    box-shadow: none !important;
}

.bottom-nav .form-control {
    border-radius: 1rem;
}

.bottom-nav .dropdown-menu {
    background-color: #0B3F54;
    border: 0;
}

.bottom-nav a:link, .bottom-nav a:visited {
    color: #ffffff;
    background-color: transparent;
}

.bottom-nav a:active, .bottom-nav a:hover {
    color: #ffffff;
    background-color: transparent;
}

@media (min-width: 1024px) {
    .navbar-expand-md .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }

    .bottom-nav {
        /*top: 110px;*/
        height: 90px;
    }
}

@media screen and (max-width: 1023px) {
    .navbar-nav .dropdown-menu {
        position: static !important;
    }

    .bottom-nav {
        /*top: 110px;*/
        padding: 0.5rem 1rem !important;
    }

        .bottom-nav .dropdown-menu {
            padding: 0px;
        }
}

@media screen and (min-width: 1024px) {
    .navbar-expand-custom {
        flex-direction: row;
        /*flex-wrap: nowrap;*/
        justify-content: flex-start;
    }

    .navbar-nav .dropdown-menu {
        position: absolute !important;
    }

    .navbar-expand-custom .navbar-nav {
        flex-direction: row;
    }

    .navbar-expand-custom .dropdown {
        position: relative
    }

    .navbar-expand-custom .dropdown-menu {
        position: absolute;
    }

    .navbar-expand-custom > .container {
        flex-wrap: nowrap;
    }

    .navbar-expand-custom .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }

    .navbar-expand-custom .navbar-toggler {
        display: none;
    }

    .navbar-collapse {
        flex-basis: 100%;
        flex-grow: 1;
        align-items: center;
    }
}

@media screen and (max-width: 400px) {
    .bottom-nav {
        /*top: 60px;*/
    }
}
/*#endregion*/

/*#region Page*/

.page-header-container {
    background-color: #f6f6f6;
    padding: 10px 15px;
}

.page-header {
    color: #7db199;
    font-size: 2.4rem;
}

.page-content-container-inner {
    padding: 15px;
}

@media screen and (max-width: 1023px) {
    .page-header {
        font-size: 2rem;
    }
}

@media screen and (max-width: 767px) {
    .page-content-container-inner {
        padding: 10px;
    }

    .page-header {
        font-size: 1.6rem;
    }
}
/*#endregion*/

/*#region Breadcrumbs*/
.breadcrumbs-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0px 10px 0px;
}

.breadcrumbs {
    margin: 0px;
    display: flex;
    list-style: none;
    font-size: 1rem;
}

    .breadcrumbs a {
        padding: 10px 10px;
        border-radius: 0.25rem;
    }

    .breadcrumbs .active {
        font-weight: 500;
    }

        .breadcrumbs a:hover, .breadcrumbs .active a {
            background-color: #ccc;
        }
/*#endregion*/

/*#region Inputs*/
.MuiFormControl-root {
    width: 100% !important;
    margin-bottom: 20px !important;
}

.search-input {
    height: 56px !important;
    margin: 0px 0px 20px 0px;
    font-size: 1rem;
    border-style: solid;
    border-width: 1px;
    border-radius: inherit;
    /*border-color: rgba(0, 0, 0, 0.23);*/
}

.makeStyles-textField-1 {
    margin: 0px 0px 20px 0px;
}
#multiple-chip-label {
    margin-top: -6px;
    padding-left:15px;
}
.MuiChip-root {
    margin-left: 5px;
    margin-right: 5px;
}

.makeStyles-textField-1 fieldset {
    border: 1px solid #ced4da;
}
.MuiFormHelperText-root.Mui-error {
    color: #f44336;
}
.checkboxTooltip {
    display: inline-block !important;
}
.checkboxTooltip button{
    padding: 0px;
}
/*#endregion*/

/*#region Buttons*/
.button {
    cursor: pointer !important;
    border-radius: 39px !important;
    padding: 0 15px !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    box-shadow: 0 0 7px 0 rgb(0 0 0 / 40%) !important;
    -webkit-box-shadow: 0 0 7px 0 rgb(0 0 0 / 40%) !important;
    -moz-box-shadow: 0 0 7px 0 rgba(0,0,0,.4) !important;
    margin-bottom: 15px !important;
    margin-left: 15px !important;
    margin-right: 15px !important;
}

.primary {
    background-color: #ecab64 !important;
    border: 0 !important;
    color: #fff !important;
}

.secondary {
    background-color: #fff !important;
    border: 3px solid #ecab64 !important;
    color: #ecab64 !important;
}

.danger {
    background-color: #ed1c24 !important;
    border: 0 !important;
    color: #fff !important;
}

.success {
    background-color: #28a745 !important;
    border: 0 !important;
    color: #fff !important;
}
/*#endregion*/

/*#region Modals*/
.MuiDialog-paper {
    margin: 0px;
    min-width: 90%;
    /*max-width: initial !important;*/
}

.MuiDialogContent-root, .MuiDialogActions-root {
    padding: 15px !important;
}

.MuiDialogTitle-root {
    background-color: #f6f6f6;
    padding: 10px 15px;
}

    .MuiDialogTitle-root h2 {
        color: #7db199;
        font-size: 1.5rem;
    }

.modal-validation-error {
    color: #f44336;
}

.modal-choose-file-row {
    padding-top: 15px;
    padding-bottom: 15px;
}

.modal-choose-file-button-container {
    text-align: center;
}

    .modal-choose-file-button-container .button {
        margin: 0 auto;
    }

.upload-file-name-container {
    text-align: center;
    color: #999;
}

@media screen and (min-width: 1024px) {
    .MuiDialog-paper {
        min-width: 45%;
    }
}

@media screen and (min-width: 1600px) {
    .MuiDialog-paper {
        min-width: 33%;
    }
}
/*#endregion*/

/*#region Data Grids*/
.MuiTableCell-head {
}

.MuiTableCell-paddingCheckbox:last-child span {
    padding-right: 11px;
}

.MuiTableBody-root tr {
    cursor: pointer;
    background-color: #ffffff;
}

    .MuiTableBody-root tr:hover {
        background-color: #ddd !important;
    }

.MuiCircularProgress-colorPrimary {
    color: #0b3f54 !important;
}

div:has(> .MuiCircularProgress-colorPrimary) {
    background-color: gray !important;
    opacity: 0.5;
}
    /*#endregion*/

/*#region Pending Request Details*/
    .itemChanged input, .itemChanged .MuiInputBase-root, .itemChanged .product-details-image {
        background-color: #bfe6c1;
    }
#multiple-chip-label {
    z-index: 999;
    margin-left: 10px;
    padding-left: 5px;
    padding-right: 5px;
    background-color: #fff;
}
.MuiBox-root .MuiChip-root {
    background-color: #0b3f54;
}
.MuiBox-root .MuiChip-label {
    color: #fff;
}
.MuiListItem-root.MuiListItem-button:hover {
    background-color: #999 !important;
    color: #fff;
}
.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover {
    background-color: #0b3f54 !important;
    color: #fff;
}
/*#endregion*/

/*#region Product Catalog*/
.control-row {
    padding-bottom: 15px;
}

.product-tile-container {
    padding: 15px;
}

.product-tile {
    cursor: pointer;
    padding: 15px;
}

    .product-tile:hover {
        background-color: #f6f6f6;
    }

.product-tile-image-container {
}

.product-tile img {
    max-width: 60%;
    max-height: 250px;
    display: block;
    margin: 10px auto;
}

.product-tile-name {
    text-align: center;
    font-weight: bold;
}

.product-tile-label {
    color: #212529;
    font-weight: 500;
    text-align: right;
    padding-right: 3px !important;
}

.product-tile-detail {
    text-align: left;
    padding-left: 3px !important;
    color: #555;
}

.product-catalog-view-tabs {
    float: right;
    padding: 0px;
    font-size: 46px;
    line-height: 46px;
}

    .product-catalog-view-tabs a {
        padding: 0px 0px 0px 20px;
    }

    .product-catalog-view-tabs:first-child {
        padding: 0px 0px 0px 0px;
    }

    .product-catalog-view-tabs i {
        color: #ccc;
    }

        .product-catalog-view-tabs i:hover, .product-catalog-view-tabs i.active {
            color: #999;
        }

@media screen and (max-width:991px) {
    .product-catalog-view-tabs {
        margin-right: 20px;
    }
}

@media screen and (max-width:680px) {
    .product-catalog-view-tabs {
        margin-right: 12px;
    }
}
.loadingContainer {
    height: 500px;
    width: 100%;
    background: no-repeat center url('assets/images/loading.gif');
    background-size: 50px 50px;
}
/*#endregion*/

/*#region Product Details*/
.product-details {
    padding-top: 15px;
}

.product-details-image-container {
    text-align: center;
}

.product-details img {
    max-width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
}

.product-details-image-button-container {
    text-align: center;
    min-height: 95px;
}

.product-detail-contact-info {
    margin: 15px 0px;
    padding: 15px;
    background-color: #f6f6f6;
}

.product-detail-edit-and-submit-button-container {
    text-align: right;
}

.product-details .MuiTextField-root {
    width: 100%;
    margin: 0px 0px 15px;
}

.section-header {
    margin: 20px 20px 20px 20px;
    padding: 0px 10px 10px 10px;
    font-size: 24px;
    font-weight: bold;
    border-bottom: solid 1px #555;
    /*width: 100%;*/
    color: #555;
}

@media screen and (max-width:767px) {
    .product-details img {
        max-width: 60%;
        max-height: 300px;
    }
}

@media screen and (min-width:1024px) {
    .product-details img {
        margin-top: 15px;
        max-width: 100%;
        max-height: 350px;
    }
}
/*#endregion*/

/*#region Account Info*/
.account-info-button-container {
    text-align: right;
}

.user-management-details h5 {
    margin-top: 30px;
    margin-bottom: 30px;
}
/*#endregion*/

/*#region Power BI*/
.DataAndInsights {
    padding: 0px !important;
}

.DataAndInsights .content-container{
    margin-bottom: 0px !important;
}

.powerBIReportEmbed iframe {
    border: 0;
}

.powerbi-nav-container {
    padding: 0px 15px;
}

.powerbi-nav-col {
    text-align: center;
    transition: all ease-in-out .2s;
    cursor: pointer;
    background-color: transparent;
    color: #0b3f54;
}

.powerbi-nav-col:hover {
    background-color: #cccccc;
    color: #0b3f54;
}

.powerbi-nav-col-active {
    background-color: #0b3f54 !important;
    color: #ffffff !important;
    cursor: auto;
}

.powerbi-nav-item {
    font-size: 1.2rem;
    font-weight: 500;
    text-decoration: none;
    display: inline-block;
    padding: 15px;
}
    /*#endregion*/

/*#region Pending Request Details*/
.itemChanged .MuiInputBase-root, .itemChanged .product-details-image-container, .itemChanged input, .itemChanged .CheckboxLabel {
    background-color: #bfe6c1;
}

.CheckboxLabel {
    padding: 10px;
    width: 100%;
}

.pending-request-details-original {
    background-color: #ddd;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 15px;
}

.pending-request-details-image-container-padding {
    padding: 15px !important;
}

.pending-request-details-image-container {
    padding: 15px !important;
}

.pending-request-details-updated {
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 15px;
}

.pending-request-details-header-container {
    text-align: center;
}

.pending-request-detail-buttons {
    text-align: right;
}
/*#endregion*/

/*#region eStore*/
.estore-product-details-image-container {
    text-align:center;
}
.estore-product-details-image-container img {
    max-width:80%;
}
.estore-product-details {
}
.estore-product-details h3 {
    font-weight: 700;
    font-size: 1.6rem;
}
.estore-product-details p {
    font-size: .85rem;
    font-weight: 400;
    color: grey;
    line-height: 1.5rem;
    margin-bottom: 0;
}
.e-store-pdf {
    cursor: pointer;
    color: #43b649 !important;
}
.e-store-pdf .fa {
    color: #000;
    font-size: 1.1rem;
}
.e-store-amount, .quantity-bag {
    color: #000 !important;
    line-height: 2rem !important;
}
.e-store-amount b {
    color: green;
    font-size: 1.5rem;
}
/*#endregion*/

/*#region Sales Rep Map General*/
.svgContainer svg{
    height: auto;
    width: 100%;
}

#RepMap path, #RepMap polygon {
    stroke: #ffffff;
    stroke-width: .25px;
    stroke-miterlimit: 10;
    transition: .3s;
}

#RepMap g{
    cursor: pointer;
}
/*#endregion*/
/*#region Sales Rep Map Text Background*/
#RepMap rect {
    fill: #c7c8ca;
    transition: .3s;
}
#RepMap text {
    color: #231f20;
}
/*#endregion*/
/*#region Sales Rep Map Zone Colors*/
#RepMap #JohnLowham path, #RepMap #JohnLowham polygon {
    fill: #0b3f54;
}

#RepMap #ErinMendes path, #RepMap #ErinMendes polygon {
    fill: #002234;
}

#RepMap #MikeBressler path, #RepMap #MikeBressler polygon {
    fill: #5a839a;
}

#RepMap #TiffanyBucher path, #RepMap #TiffanyBucher polygon {
    fill: #43b549;
}

#RepMap #JoePierson path, #RepMap #JoePierson polygon {
    fill: #a4cee7;
}

#RepMap #AlPederson path, #RepMap #AlPederson polygon {
    fill: #366177;
}

#RepMap #RandyHolliman path, #RepMap #RandyHolliman polygon {
    fill: #6fdd6e;
}

#RepMap #TimGold path, #RepMap #TimGold polygon {
    fill: #058f25;
}

#RepMap #BillyNorrell path, #RepMap #BillyNorrell polygon {
    fill: #002a00;
}

#RepMap #RickKolb path, #RepMap #RickKolb polygon {
    fill: #006b00;
}

#RepMap #PeteHausser path, #RepMap #PeteHausser polygon {
    fill: #004800;
}

#RepMap #JohnCharley path, #RepMap #JohnCharley polygon {
    fill: #7fa8c0;
}

#RepMap #JustinWood path, #RepMap #JustinWood polygon {
    fill: #43b549;
}
/*#endregion*/
/*#region Sales Rep Map Zone Hover Colors*/
#RepMap #JohnLowham:hover path, #RepMap #JohnLowham:hover polygon {
    fill: #0b3f54;
}

#RepMap #ErinMendes:hover path, #RepMap #ErinMendes:hover polygon {
    fill: #002234;
}

#RepMap #MikeBressler:hover path, #RepMap #MikeBressler:hover polygon {
    fill: #5a839a;
}

#RepMap #TiffanyBucher:hover path, #RepMap #TiffanyBucher:hover polygon {
    fill: #43b549;
}

#RepMap #JoePierson:hover path, #RepMap #JoePierson:hover polygon {
    fill: #a4cee7;
}

#RepMap #AlPederson:hover path, #RepMap #AlPederson:hover polygon {
    fill: #366177;
}

#RepMap #RandyHolliman:hover path, #RepMap #RandyHolliman:hover polygon {
    fill: #6fdd6e;
}

#RepMap #TimGold:hover path, #RepMap #TimGold:hover polygon {
    fill: #058f25;
}

#RepMap #BillyNorrell:hover path, #RepMap #BillyNorrell:hover polygon {
    fill: #002a00;
}

#RepMap #RickKolb:hover path, #RepMap #RickKolb:hover polygon {
    fill: #006b00;
}

#RepMap #PeteHausser:hover path, #RepMap #PeteHausser:hover polygon {
    fill: #004800;
}

#RepMap #JohnCharley:hover path, #RepMap #JohnCharley:hover polygon {
    fill: #7fa8c0;
}

#RepMap #JustinWood:hover path, #RepMap #JustinWood:hover polygon {
    fill: #43b549;
}
/*#endregion*/
/*#region Sales Rep Map Zone Hover Effects*/
/*Zones that are not hovered*/
#RepMap:hover g:not(:hover) path, #RepMap:hover g:not(:hover) polygon {
    filter: grayscale(85%) brightness(85%);
}
/*Manager button hover effects for zones */
#RepMap #MattHarper:hover ~ g:not(:hover) path, #RepMap #MattHarper:hover ~ g:not(:hover) polygon, #RepMap #JohnBudd:hover ~ g:not(:hover) path, #RepMap #JohnBudd:hover ~ g:not(:hover) polygon {
    filter: brightness(115%);
}
#RepMap #RonPraska:hover ~ g:not(:hover) path, #RepMap #RonPraska:hover ~ g:not(:hover) polygon, #RepMap #WaynePagel:hover ~ g:not(:hover) path, #RepMap #WaynePagel:hover ~ g:not(:hover) polygon {
    filter: brightness(115%);
}

/*Zone that is currently hovered*/
#RepMap g:hover path, #RepMap g:hover polygon {
    filter: grayscale(0) brightness(115%);
}
#WesternRegionText, #EasternRegionText {
    opacity: 0;
    transition: .3s;
}
#RepMap #RonPraska:hover ~ #WesternRegionText {
    opacity: 1;
}
#RepMap #WaynePagel:hover ~ #EasternRegionText {
    opacity: 1;
}
/*#endregion*/
/*#region Sales Rep Map Pointer Styles*/
#RepMap #pointer, #RepMap #pointer-1, #RepMap #pointer-2, #RepMap #pointer-3, #RepMap #pointer-4, #RepMap #pointer-5, #RepMap #pointer-6, #RepMap #pointer-7, #RepMap #pointer-8, #RepMap #pointer-9, #RepMap #pointer-10, #RepMap #pointer-11, #RepMap #pointer-12, #RepMap #pointer-13 {
    fill: #ebaa64 !important;
    opacity: 1 !important;
    filter: none !important;
}
#RepMap #JohnLowham:hover #pointer-8, #RepMap #ErinMendes:hover #pointer-9, #RepMap #MikeBressler:hover #pointer-13, #RepMap #TiffanyBucher:hover #pointer-7, #RepMap #JoePierson:hover #pointer-12, #RepMap #AlPederson:hover #pointer-10, #RepMap #RandyHolliman:hover #pointer-5, #RepMap #TimGold:hover #pointer-3, #RepMap #BillyNorrell:hover #pointer, #RepMap #RickKolb:hover #pointer-6, #RepMap #PeteHausser:hover #pointer-2, #RepMap #JohnCharley:hover #pointer-11, #RepMap #JustinWood:hover #pointer-4 {
    fill: #d8822e !important;
}
/*#endregion*/
/*#region Sales Rep Map Manager buttons*/
#MattHarper text, #RonPraska text, #WaynePagel text, #JohnBudd text {
    color: #5F636A;
    filter: none !important;
}

#MattHarper g text, #RonPraska g text, #WaynePagel g text, #JohnBudd g text {
    fill: #ffffff !important;
    filter: none !important;
}

#MattHarper g path, #RonPraska g path, #WaynePagel g path, #JohnBudd g path {
    fill: #ECAB64 !important;
    filter: none !important;
}
#MattHarper:hover g path, #RonPraska:hover g path, #WaynePagel:hover g path, #JohnBudd:hover g path {
    fill: #C99155 !important;
}

/*#endregion*/
/*#region Sales Rep Modal Styles*/
.repImage {
    border-radius: 100%;
    width: 250px;
    height: 250px;
    display: block;
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;
}
.repHeader {
    margin-top: 30px;
    margin-bottom: 0px;
    font-weight: 700;
    font-size: 22px;
    color: #5F636A;
    text-align: center;
}

.repTitle {
    text-align: center;
    color: #50B956;
}

.repContactInfo {
    text-align: center;
}

.salesManagerHeader {
    text-align: center;
}
.salesManagerHeader a {
    color: #007BFF !important;
    cursor: pointer;
}
.salesManagerHeader a:hover {
    text-decoration: underline !important;
}
/*#endregion*/

/*#region PO Management*/

.purchase-order-detail {
    margin-left: 20px;
    margin-right: 20px;
}

.purchase-order-detail .section-header {
    margin: 30px 0px 15px 0px;
}

.MuiPickersModal-dialogRoot {
    padding-bottom: 20px;
}
@media screen and (max-width:991px) {
    .po-management-filter-section #searchReset {
        float: right;
    }
}

/*#endregion*/