/*body{padding-top:0px !important;}*/

.homeLogin {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-image: url('../../assets/images/loginbg.png');
}
  
  .homeLogin>div {
    background-color: white;
    color: black;
    padding: 15px;
    width: 550px;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.75);
  }
  .homeLogin form{
    margin: 20px 30px 10px;
  }

  .homeLogin a{
    /* margin: 0px 30px 20px; */
    cursor: pointer;
    color: #6b6c6b;
  }

  .homeLogin a:hover{
    color: #6b6c6b;
  }

  .linkClass{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: flex-start;
    align-content: stretch;
    margin-top: -5px;
  }

  .signInClass{
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: flex-start;
      align-content: flex-start;
      padding: 30px 0px 15px;
      text-transform: none;
    }

    .signUpClass{
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: flex-start;
      align-content: flex-start;
      padding: 30px 0px 15px;
      text-transform: none;
      border-top: 1px solid #dddddd;

    }
.CheckboxLabel {
    margin-left:-5px !important;
    margin-right:0px !important;
}
.termsAndConditionsModal {
    max-width: 850px;
}