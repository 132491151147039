body {
  min-height: 100%;
  color: #555;
  background-color: #fff;
  font-family: "Open Sans",sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857;
  margin: 0px;
}
.container {
  max-width: 100% !important;
}
.spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    text-align: center;
    color: #43B649;
}
.overlay {
    position: fixed; /* Sit on top of the page content */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5); /* Black background with opacity */
    z-index: 101; /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer; /* Add a pointer on hover */
}
.cursor-pointer {
  cursor: pointer;
}